$(window).on("load",function(){
});
$(function(){

	//フォームエラー時Class付与
	jQuery(function($) {
		if ( $('.error')[0] ) {
			$('.mw_wp_form').addClass('mw_wp_form_error');
		}
	});

	// errorクラスのspan要素を持つ親にinput_errorクラスを適用
	jQuery(function($){
		$(function(){
			$("dd:has('span.error')").addClass("input_error");
		});
	});

	//自動カナ入力
	$.fn.autoKana('#name', '#kana', {katakana:true});

	$('.zip_button').on('click', function(){
		AjaxZip3.zip2addr( 'zip', '', 'address', 'address' );
		//成功時に実行する処理
		AjaxZip3.onSuccess = function() {
			$('.address').focus();
		};
		//失敗時に実行する処理
		AjaxZip3.onFailure = function() {
			alert('郵便番号に該当する住所が見つかりません');
		};
		return false;
	});

});
