$(function () {

	// スクロールアニメーション
	new WOW().init();

	//メインビジュアルスライドショー
	var mySwiper = new Swiper('.mv_swiper', {
		loop: true,
		speed: 1500,
		effect: 'fade',
		allowTouchMove: false,
		autoplay: {
			delay: 3500,
			disableOnInteraction: false,
		},
		// If we need pagination
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
	});

});