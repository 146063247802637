var ie = {
  popup: function () {
    var html = '<div class="ie-popup">\
  <style>\
  .ie-popup {\
    position: fixed;\
    top: 0;\
    left: 0;\
    z-index: 9999;\
  }\
  .ie-popup_bg {\
    position: fixed;\
    top: 0;\
    left: 0;\
    z-index: 1;\
    width: 100vw;\
    height: 100vh;\
    background-color: rgba(0,0,0,0.7);\
  }\
  .ie-popup_box {\
    background-color: #fff;\
    padding: 3em;\
    border-radius: 8px;\
    position: fixed;\
    top: 50%;\
    left: 50%;\
    transform: translateX(-50%) translateY(-50%);\
    z-index: 2;\
  }\
  .ie-popup_ttl {\
    font-weight: bold;\
    text-align: center;\
    margin-bottom: 0.8em;\
  }\
  .ie-popup ul {\
    display: flex;\
    padding: 1em 0;\
  }\
  .ie-popup ul li {\
    width: 50%;\
    padding: 0 .8em;\
    margin-right: 20px;\
  }\
  .ie-popup ul li:last-child {\
    margin-right: none;\
  }\
  .ie-popup_btn {\
    display: inline-block;\
    text-align: center;\
    border: 1px solid #333;\
    border-radius: 6px;\
    padding: 1em .8em;\
    width: 100%;\
    color: #333;\
    transition: all 0.2s ease;\
  }\
  .ie-popup_btn:hover {\
    background-color: #333;\
    color: #fff;\
  }\
  .ie-popup_close {\
    opacity: 0.7;\
    font-size: 0.8em;\
  }\
  </style>\
  <div class="ie-popup_box">\
    <p class="ie-popup_ttl">ご利用中のブラウザ<br>Internet Explorerは、本サイトにてサポートされていません。<br></p>\
    <p class="center" style="font-size:0.8em;">以下ブラウザからのご利用を推奨しています。</p>\
    <ul>\
      <li><a class="ie-popup_btn" href="https://www.google.co.jp/chrome/" target="_blank" rel="noopener">Google Chrome</a></li>\
      <li><a class="ie-popup_btn" href="https://www.microsoft.com/ja-jp/edge" target="_blank" rel="noopener">Microsoft Edge</a></li>\
    </ul>\
    <p class="right"><a class="ie-popup_close">閉じる</a></p>\
  </div>\
  <div class="ie-popup_bg"></div>\
</div>';
  document.write(html);
    return this;
  },
  popupClose: function () {
    window.addEventListener('DOMContentLoaded', function () {

      var iepopupBg = document.querySelector('.ie-popup_bg');
      var iepopupClose = document.querySelector('.ie-popup_close');
      var iepopup = document.querySelector('.ie-popup');
      iepopupBg.addEventListener("click", function () {
        iepopup.parentNode.removeChild(iepopup);
      });
      iepopupClose.addEventListener("click", function () {
        iepopup.parentNode.removeChild(iepopup);
      });

    }, false);
    return this;
  }
}

//ie
var version = window.navigator.appVersion.toLowerCase();
if (version.indexOf("msie 9.") > -1 || version.indexOf("msie 10.") > -1 || version.indexOf("trident/7") > -1) {
  ie.popup().popupClose();
}